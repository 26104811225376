import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
// Hooks
import AppContext from '@appcontext'
import useInitialState from '@hooks/useInitialState'
// Views
import { Login, Home, NotFound } from '@views'
// Components
// import NavBar from '@organisms/navbar/NavBar'
// import Loading from '@atoms/loading'

const App = () => {
  const initialState = useInitialState()

  return (
    <AppContext.Provider value={initialState}>
      <div id="app" className="d-flex flex-column h-100">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Login} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </AppContext.Provider>
  )
}

// export default App
export default withAuthenticationRequired(App, {
  onRedirecting: () => <div>Loading ...</div>
})
