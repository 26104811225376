import React, { useContext } from 'react'
import AppContext from '@appcontext'
import './orders-list.sass'

const OrdersList = () => {
  const { store, orders, dispatchStore } = useContext(AppContext)

  return (
    <>
      <div className="list-group h-100">
        {orders.map((item) => (
          <a
            className={
              item.quote_id === store.current_order.quote_id
                ? 'list-group-item list-group-item-action | d-flex justify-content-start | selected-order'
                : 'list-group-item list-group-item-action | d-flex justify-content-start'
            }
            href="#!"
            key={`${item.quote_id}-content`}
            onClick={() => {
              const orderData = {
                ...item
              }
              dispatchStore({ type: 'CURRENT_ORDER', payload: orderData })
            }}
          >
            <div className="d-flex flex-column | w-100">
              <div className="d-flex justify-content-between | mb-1">
                <span className="fw-bold">Delivery:</span>
                <span>{item.delivery_id}</span>
              </div>
              <div className="d-flex justify-content-between | mb-1">
                <span className="fw-bold">Quote:</span>
                <span>{item.quote_id}</span>
              </div>
              <div className="d-flex justify-content-between | mb-1">
                <span className="fw-bold">Worker:</span>
                <span>{item.worker_id}</span>
              </div>
              <div className="d-flex justify-content-between | mb-1">
                <span className="fw-bold">Zone:</span>
                <span>{item.zone_id}</span>
              </div>
              <div className="d-flex justify-content-between | mb-1">
                <span className="fw-bold">Status:</span>
                <span className="text-info">{item.status}</span>
              </div>
            </div>
          </a>
        ))}
      </div>
    </>
  )
}

export default OrdersList
