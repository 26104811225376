import axios from 'axios'

const changeWorkerLocation = async ({ workerId, lat, lon }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_TILE38}/set+kiwibots+${workerId}+point+${lat}+${lon}`
    )
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error))
    throw error
  }
}

export default {
  changeWorkerLocation
}
