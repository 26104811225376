import { useReducer, useState } from 'react'
import initialState from '@src/initialState'

const orderReducer = (state, action) => {
  const { payload, type } = action
  switch (type) {
    case 'CREATE_QUOTE': {
      return [
        ...state,
        {
          quote_id: payload,
          delivery_id: '',
          worker_id: '',
          zone_id: '',
          status: 'quote'
        }
      ]
    }
    case 'UPDATE_DELIVERY': {
      const index = state.findIndex(
        (item) => item.quote_id === payload.quote_id
      )
      const newState = [...state]
      newState[index] = { ...newState[index], ...payload.data }
      return newState
    }
    case 'DELETE_DELIVERY': {
      const newState = state.filter(
        (item) => item.quote_id !== payload.quote_id
      )
      return newState
    }
    case 'UPDATE_ALL_DELIVERIES': {
      return payload
    }
    default:
      return state
  }
}

const storeReducer = (state, action) => {
  const { payload, type } = action
  switch (type) {
    case 'WORKERS':
      return {
        ...state,
        workers: payload
      }
    case 'CURRENT_ORDER':
      return {
        ...state,
        current_order: { ...state.current_order, ...payload }
      }
    case 'CLEAN_CURRENT':
      return {
        ...state,
        current_order: {
          quote_id: '',
          delivery_id: '',
          worker_id: '',
          zone_id: '',
          status: ''
        }
      }
    default:
      return state
  }
}

/**
 * useInitialState - Global variables controller
 *
 * Return:
 *        {Object}      store           - General store
 *        {Function}    changestore     - Dispatcher for store
 *        {Object}      UIStore         - UI store
 *        {Function}    changeUI        - Dispatcher for UI store
 *
 */
const useInitialState = () => {
  const [orders, dispatchOrders] = useReducer(orderReducer, initialState.orders)
  const [store, dispatchStore] = useReducer(storeReducer, initialState.store)
  const [UIStore, setUI] = useState(initialState.UIStore)

  /**
   * changeUI
   * @param {Object} payload - Update UI store
   */
  const changeUI = (payload) => {
    setUI({
      ...UIStore,
      payload
    })
  }

  return {
    store,
    dispatchStore,
    UIStore,
    changeUI,
    orders,
    dispatchOrders
  }
}

export default useInitialState
