import axios from 'axios'

const updateDeliveryWorker = async ({ zoneId, deliveryId, workerId }) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_ZEUS_URL}/assigner/zones/${zoneId}/deliveries/${deliveryId}?worker_id=${workerId}&key=${process.env.REACT_APP_ZEUS_API_KEY}`
      // { params: { key: 'AIzaSyBW1WtmXDKV0qGZEFIT3eP9xFRalXmFVvo' } }
    )
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error))
    throw error
  }
}

const getAvailableWorkers = async ({ zoneId }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_ZEUS_URL}/vehicles?key=${process.env.REACT_APP_ZEUS_API_KEY}`,
      {
        params: {
          zone_id: zoneId
        }
      }
    )
    return data.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error))
    throw error
  }
}

const loadWorker = async ({ deliveryId }) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_ZEUS_URL}/delivery/load/${deliveryId}?key=${process.env.REACT_APP_ZEUS_API_KEY}`
    )
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error))
    throw error
  }
}

const markAsDelivered = async ({ deliveryId }) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_ZEUS_URL}/delivery/deliver/${deliveryId}?key=${process.env.REACT_APP_ZEUS_API_KEY}`
    )
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error))
    throw error
  }
}

const cancelDelivery = async ({ deliveryId }) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_ZEUS_URL}/delivery/cancel/${deliveryId}?key=${process.env.REACT_APP_ZEUS_API_KEY}`
    )
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error))
    throw error
  }
}

export default {
  updateDeliveryWorker,
  getAvailableWorkers,
  loadWorker,
  markAsDelivered,
  cancelDelivery
}
