import React from 'react'
import './input.scss'

const InputDouble = ({ text, action }) => (
  <div className="input-group input-group-sm mb-1">
    <span className="input-group-text fst-italic">{text}</span>
    <input
      type="number"
      placeholder="lat"
      onChange={(e) => action({ type: text, lat: e.target.value })}
      className="form-control"
    />
    <input
      type="number"
      placeholder="lng"
      onChange={(e) => action({ type: text, lng: e.target.value })}
      className="form-control"
    />
  </div>
)

export default InputDouble
