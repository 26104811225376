import axios from 'axios'

const createQuote = async ({ requesterId, pickup, dropoff } = {}) => {
  if (!requesterId) return 'no requester_id'
  const body = {
    pickup: {
      lat: !pickup.lat || pickup.lat === '' ? 37.33705663441917 : pickup.lat,
      lon: !pickup.lng || pickup.lng === '' ? -121.88997910373271 : pickup.lng,
      street: 'Sanbox test pickup',
      city: 'test',
      state: 'test',
      country: 'US',
      postal_code: '95110',
      instructions: 'Use the sandbox'
    },
    dropoff: {
      lat: !dropoff.lat || dropoff.lat === '' ? 37.33233174246858 : dropoff.lat,
      lon:
        !dropoff.lng || dropoff.lng === '' ? -121.88690980188348 : dropoff.lng,
      street: 'Sanbox test dropoff',
      city: 'test',
      state: 'test',
      country: 'US',
      postal_code: '95112',
      instructions: 'Use the sandbox',
      name: 'Sandbox test'
    },
    manifest: {
      description: 'Sanbox test',
      items: [
        {
          name: 'Catfecito',
          value: 3.99,
          quantity: 1
        }
      ]
    }
  }
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_ATHENEA_URL}/v1/requesters/${requesterId}/quotes?key=${process.env.REACT_APP_ATHENEA_API_KEY}`,
      body
    )
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error))
    throw error
  }
}

const createDelivery = async ({
  requesterId,
  quoteId,
  webhookURL,
  customId,
  email,
  phone
} = {}) => {
  if (!requesterId || !quoteId) return 'no parameters found'
  const body = {
    quote_id: quoteId,
    external_id: customId || 'Sandbox Test',
    webhook_url:
      webhookURL || 'https://kiwibot-server-v2.herokuapp.com/kiwibot-tracker',
    dropoff: {}
  }
  if (email) body.dropoff.email = email
  if (phone) body.dropoff.phone_number = phone
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_ATHENEA_URL}/v1/requesters/${requesterId}/deliveries?key=${process.env.REACT_APP_ATHENEA_API_KEY}`,
      body
    )
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error))
    throw error
  }
}

const getDeliveryStatus = async ({ requesterId, orderId } = {}) => {
  if (!requesterId || !orderId) return 'no parameters found'
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_ATHENEA_URL}/v1/requesters/${requesterId}/deliveries/${orderId}/status?key=${process.env.REACT_APP_ATHENEA_API_KEY}`
    )
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error))
    throw error
  }
}

const getRequesterDeliveries = async ({ requesterId } = {}) => {
  if (!requesterId) return 'no parameters found'
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_ATHENEA_URL}/v1/requesters/${requesterId}/deliveries?activity=open&key=${process.env.REACT_APP_ATHENEA_API_KEY}`
    )
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error))
    throw error
  }
}

export default {
  createQuote,
  createDelivery,
  getDeliveryStatus,
  getRequesterDeliveries
}
