/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react'
import ListItem from '@atoms/ListItem'
import athenea from '@utils/athenea/athenea'
import zeus from '@utils/zeus/zeus'
import tile38 from '@utils/zeus/tile38'
import AppContext from '@appcontext'
import OrdersList from '@organisms/orders-list'
import Input from '@atoms/Input'
import InputDouble from '@atoms/InputDouble'

const Login = () => {
  const { store, dispatchOrders, dispatchStore } = useContext(AppContext)
  const [requester, setRequester] = useState('')
  const [webhookURL, setWebhookURL] = useState('')
  const [customId, setCustomId] = useState('')
  const [email, setEmail] = useState('')
  const [pickup, setPickup] = useState({
    lat: 37.33705663441917,
    lng: -121.88997910373271
  })
  const [dropoff, setDropoff] = useState({
    lat: 37.33233174246858,
    lng: -121.88690980188348
  })
  const [phone, setPhone] = useState('')
  const [workers, setWorkers] = useState([])

  const getWorkers = async () => {
    const data = await zeus.getAvailableWorkers({
      zoneId: store.current_order.zone_id
    })
    const availableWorkers = data.workers.filter((item) => item.status === 1)
    setWorkers(availableWorkers)
  }

  useEffect(() => {
    if (store.current_order.zone_id === '') return
    getWorkers()
  }, [store])

  /* STEP 0.1: Get requester ID deliveries */
  const getRequesterDeliveries = async () => {
    if (requester === '') {
      // eslint-disable-next-line no-alert
      window.alert('No requester id found')
      return
    }
    const data = await athenea.getRequesterDeliveries({
      requesterId: requester
    })
    if (data.deliveries.length === 0) return
    const newOrders = []
    const { deliveries } = data
    for (let i = 0; i < deliveries.length; i += 1) {
      const item = {
        delivery_id: deliveries[i].id,
        dropoff: deliveries[i].dropoff,
        pickup: deliveries[i].pickup,
        status: deliveries[i].status,
        quote_id: deliveries[i].quote_id,
        worker_id: deliveries[i].worker_id,
        zone_id: deliveries[i].zone_id
      }
      newOrders.push(item)
    }
    dispatchOrders({
      type: 'UPDATE_ALL_DELIVERIES',
      payload: newOrders
    })
    dispatchStore({ type: 'CLEAN_CURRENT' })
  }

  /* STEP 0: Set pickup and dropoff */
  const updatePickup = ({ type, lat, lng }) => {
    if (type === 'pickup') {
      if (lat) setPickup({ ...pickup, lat: parseFloat(lat) })
      else setPickup({ ...pickup, lng: parseFloat(lng) })
      return
    }
    if (lat) setDropoff({ ...dropoff, lat: parseFloat(lat) })
    else setDropoff({ ...dropoff, lng: parseFloat(lng) })
  }

  /* STEP 1: Create quote */
  const generateQuote = async () => {
    if (requester === '') return
    const data = await athenea.createQuote({
      requesterId: requester,
      pickup,
      dropoff
    })
    if (!data || data === {}) return
    dispatchStore({
      type: 'CURRENT_ORDER',
      payload: { quote_id: data.id, status: 'quote' }
    })
    dispatchOrders({ type: 'CREATE_QUOTE', payload: data.id })
  }

  /* STEP 2: Create delivery */
  const generateDelivery = async () => {
    if (store.current_order.quote_id === '' || requester === '') {
      // eslint-disable-next-line no-alert
      window.alert('Create a quote first')
      return
    }

    const data = await athenea.createDelivery({
      requesterId: requester,
      quoteId: store.current_order.quote_id,
      webhookURL,
      customId,
      email,
      phone
    })
    if (!data || data === {}) return
    const orderData = {
      delivery_id: data.id,
      dropoff: data.dropoff,
      pickup: data.pickup,
      status: 'pending',
      zone_id: data.zone_id
    }
    dispatchOrders({
      type: 'UPDATE_DELIVERY',
      payload: { quote_id: store.current_order.quote_id, data: orderData }
    })
    dispatchStore({ type: 'CURRENT_ORDER', payload: orderData })

    await getWorkers()
  }

  /* STEP 3: Assign worker */
  const selectWorker = async () => {
    if (
      store.current_order.zone_id === '' ||
      store.current_order.delivery_id === '' ||
      store.current_order.worker_id === ''
    ) {
      // eslint-disable-next-line no-alert
      window.alert('Create a delivery first')
      return
    }

    await zeus.updateDeliveryWorker({
      zoneId: store.current_order.zone_id,
      deliveryId: store.current_order.delivery_id,
      workerId: store.current_order.worker_id
    })
    const orderData = {
      status: 'assigned'
    }
    dispatchOrders({
      type: 'UPDATE_DELIVERY',
      payload: { quote_id: store.current_order.quote_id, data: orderData }
    })
    dispatchStore({ type: 'CURRENT_ORDER', payload: orderData })
  }

  /* STEP 4: Move worker to restaurant */
  const updateWorkerLocationArrivingAtPickup = async () => {
    if (
      store.current_order.zone_id === '' ||
      store.current_order.delivery_id === '' ||
      store.current_order.worker_id === ''
    ) {
      // eslint-disable-next-line no-alert
      window.alert('Create a delivery first')
      return
    }
    const data = await tile38.changeWorkerLocation({
      workerId: store.current_order.worker_id,
      lat: store.current_order.pickup.lat,
      lon: store.current_order.pickup.lon
    })
    // console.log(data)
    const orderData = {
      status: 'arriving_pickup'
    }
    dispatchOrders({
      type: 'UPDATE_DELIVERY',
      payload: { quote_id: store.current_order.quote_id, data: orderData }
    })
    dispatchStore({ type: 'CURRENT_ORDER', payload: orderData })
  }

  /* STEP 5: Move worker to restaurant again */
  const updateWorkerLocationAtPickup = async () => {
    if (
      store.current_order.zone_id === '' ||
      store.current_order.delivery_id === '' ||
      store.current_order.worker_id === ''
    ) {
      // eslint-disable-next-line no-alert
      window.alert('Create a delivery first')
      return
    }
    const data = await tile38.changeWorkerLocation({
      workerId: store.current_order.worker_id,
      lat: store.current_order.pickup.lat,
      lon: store.current_order.pickup.lon
    })
    // console.log(data)
    const orderData = {
      status: 'at_pickup'
    }
    dispatchOrders({
      type: 'UPDATE_DELIVERY',
      payload: { quote_id: store.current_order.quote_id, data: orderData }
    })
    dispatchStore({ type: 'CURRENT_ORDER', payload: orderData })
  }

  /* STEP 6: Load worker */
  const loadWorker = async () => {
    if (store.current_order.delivery_id === '') {
      // eslint-disable-next-line no-alert
      window.alert('Create a delivery first')
      return
    }
    await zeus.loadWorker({
      deliveryId: store.current_order.delivery_id
    })
    const orderData = {
      status: 'in_transit'
    }
    dispatchOrders({
      type: 'UPDATE_DELIVERY',
      payload: { quote_id: store.current_order.quote_id, data: orderData }
    })
    dispatchStore({ type: 'CURRENT_ORDER', payload: orderData })
  }

  /* STEP 7: Move worker to dropoff */
  const updateWorkerLocationArrivingAtDropoff = async () => {
    if (
      store.current_order.zone_id === '' ||
      store.current_order.delivery_id === '' ||
      store.current_order.worker_id === ''
    ) {
      // eslint-disable-next-line no-alert
      window.alert('Create a delivery first')
      return
    }
    const data = await tile38.changeWorkerLocation({
      workerId: store.current_order.worker_id,
      lat: store.current_order.dropoff.lat,
      lon: store.current_order.dropoff.lon
    })
    // console.log(data)
    const orderData = {
      status: 'arriving_dropoff'
    }
    dispatchOrders({
      type: 'UPDATE_DELIVERY',
      payload: { quote_id: store.current_order.quote_id, data: orderData }
    })
    dispatchStore({ type: 'CURRENT_ORDER', payload: orderData })
  }

  /* STEP 8: Move worker to dropoff again */
  const updateWorkerLocationAtDropoff = async () => {
    if (
      store.current_order.zone_id === '' ||
      store.current_order.delivery_id === '' ||
      store.current_order.worker_id === ''
    ) {
      // eslint-disable-next-line no-alert
      window.alert('Create a delivery first')
      return
    }
    const data = await tile38.changeWorkerLocation({
      workerId: store.current_order.worker_id,
      lat: store.current_order.dropoff.lat,
      lon: store.current_order.dropoff.lon
    })
    // console.log(data)
    const orderData = {
      status: 'at_dropoff'
    }
    dispatchOrders({
      type: 'UPDATE_DELIVERY',
      payload: { quote_id: store.current_order.quote_id, data: orderData }
    })
    dispatchStore({ type: 'CURRENT_ORDER', payload: orderData })
  }

  /* STEP 9: Mark order as delivered */
  const markAsDelivered = async () => {
    if (store.current_order.delivery_id === '') {
      // eslint-disable-next-line no-alert
      window.alert('Create a delivery first')
      return
    }
    await zeus.markAsDelivered({
      deliveryId: store.current_order.delivery_id
    })
    const orderData = {
      status: 'delivered'
    }
    dispatchOrders({
      type: 'UPDATE_DELIVERY',
      payload: { quote_id: store.current_order.quote_id, data: orderData }
    })
    dispatchStore({ type: 'CLEAN_CURRENT' })
  }

  /* STEP 20: Mark order as cancel */
  const cancelDelivery = async () => {
    if (store.current_order.delivery_id === '') {
      // eslint-disable-next-line no-alert
      window.alert('Create a delivery first')
      return
    }
    await zeus.cancelDelivery({
      deliveryId: store.current_order.delivery_id
    })
    const orderData = {
      status: 'canceled'
    }
    dispatchOrders({
      type: 'UPDATE_DELIVERY',
      payload: { quote_id: store.current_order.quote_id, data: orderData }
    })
    dispatchStore({ type: 'CURRENT_ORDER', payload: orderData })
  }

  return (
    <div className="container-fluid mt-2 h-100">
      <div className="d-flex flex-column justify-content-center align-items-center">
        {/* Form */}
        <div className="col-9 | mb-3">
          <div className="d-flex justify-content-between">
            <div className="col-5">
              <Input text="requester id" action={setRequester} />
              <Input text="webhook url" action={setWebhookURL} />
              <Input text="external id" action={setCustomId} />
              <Input text="email" action={setEmail} />
              <Input text="phone" action={setPhone} />
              <InputDouble text="pickup" action={updatePickup} />
              <InputDouble text="dropoff" action={updatePickup} />
            </div>
            {/* Buttons */}
            <div className="d-flex flex-column justify-content-center">
              <button
                type="button"
                className="btn btn-outline-info mb-1"
                onClick={getRequesterDeliveries}
              >
                Get Active Deliveries
              </button>
              <button
                type="button"
                className="btn btn-outline-success mb-1"
                onClick={generateQuote}
              >
                Create Quote
              </button>
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={generateDelivery}
              >
                Create Delivery
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Data */}
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-md-7">
            <div className="mb-4">
              <div className="d-flex justify-content-between | mb-1">
                <span className="fw-bold">Requester:</span>
                <span>{requester}</span>
              </div>
              <div className="d-flex justify-content-between | mb-1">
                <span className="fw-bold">Quote:</span>
                <span>{store.current_order.quote_id}</span>
              </div>
              <div className="d-flex justify-content-between | mb-1">
                <span className="fw-bold">Webhook:</span>
                <span>{webhookURL}</span>
              </div>
              <div className="d-flex justify-content-between | mb-1">
                <span className="fw-bold">Delivery:</span>
                <span>{store.current_order.delivery_id}</span>
              </div>
            </div>
            {/* Status */}
            <div className="mb-3 mt-5">
              <h5>Update status</h5>
              <ul className="list-group list-group-flush">
                {store.current_order.status === 'pending' && (
                  <>
                    <div className="input-group my-2 | d-flex justify-content-center ">
                      <select
                        className="custom-select"
                        onChange={(e) => {
                          const orderData = {
                            worker_id: e.target.value
                          }
                          dispatchOrders({
                            type: 'UPDATE_DELIVERY',
                            payload: {
                              quote_id: store.current_order.quote_id,
                              data: orderData
                            }
                          })
                          dispatchStore({
                            type: 'CURRENT_ORDER',
                            payload: orderData
                          })
                        }}
                      >
                        <option defaultValue="">Select worker</option>
                        {workers.map((item) => (
                          <option key={item.vehicle_id} value={item.vehicle_id}>
                            {item.vehicle_id}
                          </option>
                        ))}
                      </select>
                    </div>
                    <ListItem status="assigned" action={selectWorker} />
                  </>
                )}
                {store.current_order.status === 'assigned' && (
                  <ListItem
                    status="arriving_pickup"
                    action={updateWorkerLocationArrivingAtPickup}
                  />
                )}
                {store.current_order.status === 'arriving_pickup' && (
                  <ListItem
                    status="at_pickup"
                    action={updateWorkerLocationAtPickup}
                  />
                )}
                {store.current_order.status === 'at_pickup' && (
                  <ListItem status="in_transit" action={loadWorker} />
                )}

                {store.current_order.status === 'in_transit' && (
                  <ListItem
                    status="arriving_dropoff"
                    action={updateWorkerLocationArrivingAtDropoff}
                  />
                )}
                {store.current_order.status === 'arriving_dropoff' && (
                  <ListItem
                    status="at_dropoff"
                    action={updateWorkerLocationAtDropoff}
                  />
                )}

                {store.current_order.status === 'at_dropoff' && (
                  <ListItem status="delivered" action={markAsDelivered} />
                )}

                {store.current_order.status !== 'delivered' && (
                  <ListItem
                    status="Mark as canceled?"
                    action={cancelDelivery}
                  />
                )}
              </ul>
            </div>
          </div>

          <div className="col-md-5">
            <OrdersList />
          </div>
        </div>
      </div>
      <p className="fst-italic fw-light mt-5">
        NOTE:{' '}
        <span className="text-uppercase fw-bold">
          If you do not insert pickup or dropoff the default location would be
          San Jose
        </span>
        , If you find some error or feedback send it to fredy@kiwibot.com,
        thanks
      </p>
      <p className="fst-italic fw-light mt-5">
        VERSION: {process.env.REACT_APP_VERSION}
      </p>
    </div>
  )
}

export default Login
