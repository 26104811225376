import React from 'react'
import './input.scss'

const Input = ({ text, action = () => {} }) => (
  <div className="input-group input-group-sm mb-1">
    <span className="input-group-text fst-italic">{text}</span>
    <input
      type="text"
      className="form-control"
      onChange={(e) => action(e.target.value)}
    />
  </div>
)

export default Input
