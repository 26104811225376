const store = {
  loading: 'False',
  workers: [],
  current_order: {
    quote_id: '',
    delivery_id: '',
    dropoff: { lat: '', lon: '' },
    pickup: { lat: '', lon: '' },
    worker_id: '',
    zone_id: '',
    status: ''
  }
}

const UIStore = {
  user_language: 'en'
}

const orders = []

export default {
  store,
  UIStore,
  orders
}
