import React from 'react'

const ListItem = ({ status, action = () => {} }) => (
  <li className="list-group-item">
    <div className="d-flex justify-content-between | px-2">
      <div className="d-flex align-items-center">
        {/* <i className="material-icons me-1" style={{ fontSize: '18px' }}>
          { check !== "True" ? 'radio_button_unchecked' : 'radio_button_checked' }
        </i> */}
        <p className="m-0 me-1">Update status to</p>
        <p className="m-0 | d-flex align-items-center | fw-bold">{status}</p>
      </div>
      <button type="button" className="btn btn-secondary" onClick={action}>
        Run
      </button>
    </div>
  </li>
)

export default ListItem
